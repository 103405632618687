import { action } from "typesafe-actions";
import {
  IInventoryItem,
  InventoryItemsActionsTypes,
  LoadAllInventoryItemsAction,
  ResetItemReducerAction,
  StoreAllInventoryItemsAction,
  StoreInventoryItemAction,
} from '../../types/inventoryItemActions';

export const loadAllInventoryItemsAction = (): LoadAllInventoryItemsAction =>
    action(InventoryItemsActionsTypes.LOAD_All_ITEMS);

export const storeAllInventoryItemsAction = (payload:IInventoryItem[]): StoreAllInventoryItemsAction =>
    action(InventoryItemsActionsTypes.STORE_ALL_ITEMS,payload);

export const storeCurrentInventoryItemAction = (payload?:IInventoryItem): StoreInventoryItemAction =>
    action(InventoryItemsActionsTypes.STORE_CURRENT_INVENTORY_ITEM,payload);

export const resetItemReducerAction = (): ResetItemReducerAction =>
    action(InventoryItemsActionsTypes.RESET_ITEM_REDUCER);
