import { AnyAction } from "redux";

export interface IProductionLocation {
  id: number;
  name: string;
  code: string;
  role: string;
}

export interface IInventoryItem {
  description: string;
  gmn: string;
  gtin: string;
  variant: string;
  id: number;
  mainImageId: number;
  name: string;
  ortoId: number;
  productionLocation: IProductionLocation;
  stickerRevPackage: string;
  stickerRevProduct: string;
}

export interface LotNoDocEntity{
  id:number;
  itemId:number;
  lotCode:string;
  productionDate:Date;
  quantity:number;
  mainImageId:number;
  producerCode:string;
  ortoId:string;
  variant:string;
  name:string;
  description:string;
  gmn:string;
  gtin:string;
  stickerRevPackage:string;
  stickerRevProduct:string;
  packageDocumentId:number;
  productDocumentId:number;
}

export interface IInventoryItemsState {
  inventoryItems: IInventoryItem[];
  currentInventoryItem?: IInventoryItem
}

export enum InventoryItemsActionsTypes {
  LOAD_All_ITEMS = "inventoryItems/LOAD_All_ITEMS",
  STORE_ALL_ITEMS = "inventoryItems/STORE_ALL_ITEMS",
  STORE_CURRENT_INVENTORY_ITEM = "inventoryItems/STORE_CURRENT_INVENTORY_ITEM",
  RESET_ITEM_REDUCER = "inventoryItems/RESET_ITEM_REDUCER",
}

export interface LoadAllInventoryItemsAction {
  type: InventoryItemsActionsTypes.LOAD_All_ITEMS;
}

export interface StoreInventoryItemAction {
  type: InventoryItemsActionsTypes.STORE_CURRENT_INVENTORY_ITEM;
  payload?: IInventoryItem;
}
export interface StoreAllInventoryItemsAction {
  type: InventoryItemsActionsTypes.STORE_ALL_ITEMS;
  payload: IInventoryItem[];
}

export interface ResetItemReducerAction {
  type: InventoryItemsActionsTypes.RESET_ITEM_REDUCER;
  payload?: undefined;
}

export type InventoryItemsActions =
  | LoadAllInventoryItemsAction
  | StoreAllInventoryItemsAction
  // @ts-ignore
  | AnyAction<any>;
