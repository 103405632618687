import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllInventoryItemsAction, storeCurrentInventoryItemAction } from '../redux/actions/inventoryItemActions';
import { Breadcrumbs, Container, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, csCZ, enUS, GridRenderCellParams } from '@mui/x-data-grid';
import { selectInventoryItems } from '../redux/selectors/inventoryItemSelectors';
import { selectAppConfig } from '../redux/selectors/appSelectors';
import Button from '@mui/material/Button';
import Routes from '../utils/Routes';
import { NavLink, useHistory } from 'react-router-dom';
import { loadLotHistoryAction } from '../redux/actions/lotActions';

export const InventoryItemsPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const inventoryItems = useSelector(selectInventoryItems);
  const appConfig = useSelector(selectAppConfig);
  const lagSelected: string = i18n.language;
  const dataGridLanguage: any = lagSelected === 'cs' ? csCZ : enUS;
  const columns: GridColDef[] = [
    {
      field: 'mainImageId',
      headerName: t('InventoryItemsPage.image'),
      width: 90,
      renderCell: (params) => <img height={90} src={`${appConfig?.apiUrl}/listItemImages/${params.value}`} />,
    },
    { field: 'ortoId', headerName: t('InventoryItemsPage.id'), width: 70 },
    { field: 'name', headerName: t('InventoryItemsPage.name'), width: 200 },
    {
      field: 'description',
      headerName: t('InventoryItemsPage.description'),
      width: 230,
    },
    {
      field: 'gmn',
      headerName: t('InventoryItemsPage.gmn'),
      width: 160,
      hide: true,
    },
    {
      field: 'gtin',
      headerName: t('InventoryItemsPage.gtin'),
      width: 160,
      hide: true,
    },
    {
      field: 'stickerRevPackage',
      headerName: t('InventoryItemsPage.stickerRevPackage'),
      width: 160,
      hide: true,
    },
    {
      field: 'stickerRevProduct',
      headerName: t('InventoryItemsPage.stickerRevItem'),
      width: 160,
      hide: true,
    },
    {
      field: '',
      headerName: '',
      width: 350,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <Button
            color={'primary'}
            variant="contained"
            onClick={() => {
              showHistory(params);
            }}
          >
            {t('buttons.history')}
          </Button>
          &nbsp; &nbsp;
          <Button
            color={'primary'}
            variant="contained"
            onClick={() => {
              showNewLot(params);
            }}
          >
            {t('buttons.newLot')}
          </Button>
        </>
      ),
    },
  ];

  const showHistory = (item: GridRenderCellParams) => {
    dispatch(storeCurrentInventoryItemAction(item.row));
    dispatch(loadLotHistoryAction());
    history.push(Routes.LOT_HISTORY);
  };
  const showNewLot = (item: GridRenderCellParams) => {
    dispatch(storeCurrentInventoryItemAction(item.row));
    history.push(Routes.NEW_LOT);
  };

  useEffect(() => {
    dispatch(loadAllInventoryItemsAction());
  }, []);

  return (
    <Container style={{ width: '100%', maxWidth: '100%', paddingTop: 10 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to={Routes.INVENTORY_LIST}>{t('InventoryItemsPage.title')}</NavLink>
      </Breadcrumbs>
      <h1>{t('InventoryItemsPage.title')}</h1>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
          rows={inventoryItems}
          columns={columns}
          autoHeight={true}
          rowHeight={100}
        />
      </div>
    </Container>
  );
};
