import {Reducer} from 'redux';
import {AppAction, AppActionTypes, IAppState} from '../../types';

const initialState: IAppState = {
    ready: false,
    authenticated: false,
    menuKey: 'menu.itemList'
};

export const appReducer: Reducer<IAppState> = (state = initialState, action: AppAction) => {
    switch (action.type) {
        case AppActionTypes.STORE_CONFIG: {
            return {...state, config: action.payload, ready: true};
        }
        case AppActionTypes.STORE_AUTHENTICATE: {
            return {...state, authenticated: true, userDetail: action.payload};
        }
        case AppActionTypes.STORE_MENU: {
            return {...state, menuKey: action.payload};
        }
        default:
            return state;
    }
};
