import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AppConfig, OResponse } from '../types';
import storage from '../utils/localStorage';
import { apiEndpoints, ApiInterface, Endpoint } from './endpoints';
import { dispatch } from '../redux/configureStore';
import { addInfoAction } from '../redux/actions/infoActions';

// @ts-ignore
let axiosInstance:ApiInterface = {};

const createApi = (requests: any, config: AppConfig) => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    const api = axios.create({
        baseURL: config.apiUrl,
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Access-Control-Allow-Origin': '*'
        },
        timeout: 600000,
        validateStatus: (status: number) =>
            // work with HTTP:304 as a successfull response
            (status >= 200 && status < 300) || status === 304
    });

    const securedConfig: AxiosRequestConfig = { headers: {} };
    // token can be changed during process so add it to the header dynamically
    api.interceptors.request.use((config: any) => {
        if (storage.getItem('token')) {
            config.headers.Authorization = `Bearer ${storage.getItem('token')}`;
        }
        return config;
    });

    api.interceptors.response.use(
        response => response,
        error => {
            if (error.message === 'Network Error') {
                dispatch(addInfoAction({
                    type: 'info',
                    title: 'error.networkError',
                    severity: 'ERROR'
                }));
            } else if (error.response.status === 401 || error.response.status === 403) {
                dispatch(addInfoAction({
                    type: 'info',
                    title: 'error.accessDenied',
                    severity: 'ERROR'
                }));
            } else if (error.response.status >= 500) {
                dispatch(addInfoAction({
                    type: 'info',
                    title: 'error.errorServer',
                    severity: 'ERROR'
                }));
            } else {
                dispatch(addInfoAction({
                    type: 'info',
                    title: 'error.errorUnspecified',
                    severity: 'ERROR'
                }));
            }
            if (error.response) {
                return {
                    ...error.response,
                    message: error.message,
                    stack: error.stack
                };
            }

            return error;
        }
    );

    return requests(api, securedConfig);
};

const init = (config: AppConfig): void => {
    axiosInstance = createApi(createRequests, config);
};

interface CustomAxiosInstance extends AxiosInstance {
    [key: string]: any
}

export interface JwtCheckRequest{
    token:string
}

const createRequests = (api: CustomAxiosInstance, securedConfig: AxiosRequestConfig): ApiInterface => {
    const preparedEndpoints: any = {};
    const openApiEndpoints = apiEndpoints;
    const keys = Object.keys(openApiEndpoints);

    keys.forEach((key: string) => {
        const endpoint: Endpoint = openApiEndpoints[key];
        const method: string = endpoint.method.toLowerCase();
        const url = `${endpoint.secured ? '/secured' : '/unsecured'}${endpoint.url}`;
        preparedEndpoints[key] = (data?: any) =>
            api[method](url, data || undefined, endpoint.secured ? securedConfig : undefined);
    });

    return preparedEndpoints as ApiInterface;
};

export const processResponseStatus = (response: any): OResponse<any> => {
    const status = response.status;

    if (response.message === 'Network Error') {
        return {
            ...response,
            isHttpOk: false
        };
    } else if (status === 200) {
        return {
            ...response,
            isHttpOk: true
        };
    }
    return {
        ...response,
        isHttpOk: false
    };
};

export { axiosInstance };
export default { init };

