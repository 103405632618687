import { ILoginFormFields } from "./forms";

export interface AppConfig {
  apiUrl: string;
  appMode: string;
  appName: string;
  debugMode: boolean;
}

export interface UserDetail {
  sub: string;
  roles: SimpleGrantedAuthority[];
}

export interface IAppState {
  config?: AppConfig;
  ready: boolean;
  menuKey: string;
  authenticated: boolean;
  userDetail?: UserDetail;
}

export interface SimpleGrantedAuthority {
  authority: string;
}

export enum AppActionTypes {
  LOAD_CONFIG = "app/LOAD_CONFIG",
  STORE_CONFIG = "app/STORE_CONFIG",
  APP_READY = "app/APP_READY",
  APP_AUTHENTICATE = "app/APP_AUTHENTICATE",
  STORE_AUTHENTICATE = "app/APP_STORE_AUTHENTICATE",
  STORE_MENU = "app/STORE_MENU",
}

export interface AppReadyAction {
  type: AppActionTypes.APP_READY;
}

export interface LoadConfigAction {
  type: AppActionTypes.LOAD_CONFIG;
}

export interface AppAuthenticateAction {
  type: AppActionTypes.APP_AUTHENTICATE;
  payload: ILoginFormFields;
}

export interface AppStoreAuthenticateAction {
  type: AppActionTypes.STORE_AUTHENTICATE;
  payload: UserDetail;
}

export interface StoreConfigAction {
  type: AppActionTypes.STORE_CONFIG;
  payload: AppConfig;
}

export interface StoreMenuActionAction {
  type: AppActionTypes.STORE_MENU;
  payload: string;
}

export type AppAction =
  | LoadConfigAction
  | StoreConfigAction
  | StoreMenuActionAction
  | AppStoreAuthenticateAction;
