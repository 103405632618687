import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { appAuthenticateAction } from '../redux/actions/appActions';
import { ILoginFormFields } from '../types';
import { useTranslation } from 'react-i18next';
import { Button, Container, Grid } from '@mui/material';

import { TextField } from 'mui-rff';
import { validateLoginForm } from './LoginPage.validate';

const sxStyles = {
  container: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
};

export const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleSubmit = (values: ILoginFormFields) => {
    dispatch(appAuthenticateAction(values));
  };
  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validateLoginForm(values, t)}
      subscription={{ values: true, submitting: true }}
      render={(props: FormRenderProps<ILoginFormFields>) => {
        const { handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit}>
            <Container sx={sxStyles.container} maxWidth="xs">
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      <TextField label={t('loginPage.userName')} name="username" />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextField type={'password'} label={t('loginPage.password')} name="password" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                  <Button color="primary" fullWidth={true} type="submit" variant="contained">
                    {t('buttons.login')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        );
      }}
    />
  );
};
