import { Reducer } from "redux";
import {
  InventoryItemsActions,
  InventoryItemsActionsTypes,
  IInventoryItemsState,
} from "../../types/inventoryItemActions";

const initialState: IInventoryItemsState = {
  inventoryItems: [],
};

export const inventoryItemsReducer: Reducer<any> = (
  state = initialState,
  action: InventoryItemsActions
) => {
  switch (action.type) {
    case InventoryItemsActionsTypes.STORE_ALL_ITEMS: {
      return { ...state, inventoryItems: action.payload };
    }
    case InventoryItemsActionsTypes.STORE_CURRENT_INVENTORY_ITEM: {
      return { ...state, currentInventoryItem: action.payload };
    }
    default:
      return state;
  }
};
