import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { PrintLotPage } from './pages/PrintLotPage';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          'error.networkError': 'Network Error',
          'error.accessDenied': 'Access denied',
          'error.errorServer': 'Error processing request',
          'error.errorUnspecified': 'Error processing request',

          'validationMessages.required': 'This field is required.',

          'loginPage.userName': 'User Name',
          'loginPage.password': 'Password',

          'UserProfileDialog.title': 'Details of logged user',
          'UserProfileDialog.text': 'User can print stickers for items produced in locations:',

          'toolbar.loggedUser': 'Logged user:',

          'buttons.login': 'Log in',
          'buttons.history': 'History',
          'buttons.newLot': 'New production LOT',
          'buttons.generateLabels': 'Generate labels',
          'buttons.historyLotPrint': 'Open for addition label printing',

          'menu.itemList': 'Product list',

          'InventoryItemsPage.title': 'Product list',
          'InventoryItemsPage.image': 'Picture',
          'InventoryItemsPage.id': 'ID',
          'InventoryItemsPage.name': 'Name',
          'InventoryItemsPage.description': 'Description',
          'InventoryItemsPage.gmn': 'Gmn',
          'InventoryItemsPage.gtin': 'Gtin',
          'InventoryItemsPage.stickerRevPackage': 'Package sticker rev.',
          'InventoryItemsPage.stickerRevItem': 'Item sticker rev.',

          'LotHistoryPage.title': 'Lot history',

          'NewLotPage.title': 'New production lot',
          'NewLotPage.ortoId': 'Orto ID',
          'NewLotPage.name': 'Name',
          'NewLotPage.lotCode': 'New LOT Nr.',
          'NewLotPage.productionDate': 'Production Date',
          'NewLotPage.quantity': 'Quantity',

          'PrintLotPage.title': 'Print production lot',
          'PrintLotPage.ortoId': 'Orto ID',
          'PrintLotPage.name': 'Name',
          'PrintLotPage.lotCode': 'LOT Nr.',
          'PrintLotPage.productionDate': 'Production Date',
          'PrintLotPage.quantity': 'Quantity',
          'PrintLotPage.packagePdf': 'Download package sticker',
          'PrintLotPage.productPdf': 'Download item sticker',

          'LotHistoryPage.picture': 'Picture',
          'LotHistoryPage.ortoId_variant': 'Orto ID',
          'LotHistoryPage.item': 'Item',
          'LotHistoryPage.lot': 'LOT',
          'LotHistoryPage.productionDate': 'Production date',
          'LotHistoryPage.quantity': 'Quantity',
        },
      },
      cs: {
        translations: {
          'error.networkError': 'Chyba připojeni',
          'error.accessDenied': 'Přístup Zamítnut',
          'error.errorServer': 'Chyba při zpracování požadavku.',
          'error.errorUnspecified': 'Chyba při zpracování požadavku.',

          'validationMessages.required': 'Toto pole je povinné.',

          'loginPage.userName': 'Uživatelské jméno',
          'loginPage.password': 'Heslo',

          'UserProfileDialog.title': 'Informace o uživateli',
          'UserProfileDialog.text': 'Uživatel může tisknout popisky vyráběné v lokaci:',

          'toolbar.loggedUser': 'Přihlášený uživatel',

          'buttons.login': 'Přihlásit',
          'buttons.history': 'Historie',
          'buttons.newLot': 'Nová výrobní šarže',
          'buttons.generateLabels': 'Generovat popisky',
          'buttons.historyLotPrint': 'Otevřít pro dodatečný tisk štítků',

          'menu.itemList': 'Seznam výrobků',

          'InventoryItemsPage.title': 'Seznam výrobků',
          'InventoryItemsPage.image': 'Obrázek',
          'InventoryItemsPage.id': 'ID',
          'InventoryItemsPage.name': 'Název',
          'InventoryItemsPage.description': 'Popis',
          'InventoryItemsPage.gmn': 'Gmn',
          'InventoryItemsPage.gtin': 'Gtin',
          'InventoryItemsPage.stickerRevPackage': 'Rev. štítku balení',
          'InventoryItemsPage.stickerRevItem': 'Rev štítku výrobku',

          'LotHistoryPage.title': 'Historie šarží',

          'NewLotPage.title': 'Nová výrobní šarže',
          'NewLotPage.ortoId': 'Orto ID',
          'NewLotPage.name': 'Název',
          'NewLotPage.lotCode': 'Nové číslo šarže',
          'NewLotPage.productionDate': 'Datum výroby',
          'NewLotPage.quantity': 'Počet kusů',

          'PrintLotPage.title': 'Tisk výrobní šarže',
          'PrintLotPage.ortoId': 'Orto ID',
          'PrintLotPage.name': 'Název',
          'PrintLotPage.lotCode': 'Číslo šarže',
          'PrintLotPage.productionDate': 'Datum výroby',
          'PrintLotPage.quantity': 'Počet kusů',
          'PrintLotPage.packagePdf': 'Stáhnout štítek balení',
          'PrintLotPage.productPdf': 'Stáhnout štítek výrobku',

          'LotHistoryPage.picture': 'Obrázek',
          'LotHistoryPage.ortoId_variant': 'Orto ID',
          'LotHistoryPage.item': 'Položka',
          'LotHistoryPage.lot': 'Šarže',
          'LotHistoryPage.productionDate': 'Datum výroby',
          'LotHistoryPage.quantity': 'Počet kusů',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
