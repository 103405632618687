import React, { useEffect } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { appLoadConfigAction } from "./redux/actions/appActions";
import {
  selectAppAuthenticated,
  selectAppReady,
} from "./redux/selectors/appSelectors";
import { BasicLayout } from "./containers/App/BasicLayout";
import Routes from "./utils/Routes";
import { LoginPage } from "./pages/LoginPage";
import { Redirect, Route, Router, Switch} from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { AuthLayout } from "./containers/App/AuthLayout";
import { InventoryItemsPage } from "./pages/InventoryItemsPage";
import { LotHistoryPage } from "./pages/LotHistoryPage";
import { NewLotPage } from "./pages/NewLotPage";
import { createBrowserHistory } from 'history';
import { PrintLotPage } from './pages/PrintLotPage';

function App() {
  const dispatch = useDispatch();
  const appReady = useSelector(selectAppReady);
  const authenticated = useSelector(selectAppAuthenticated);
  var history = createBrowserHistory();
  useEffect(() => {
    dispatch(appLoadConfigAction());
  }, []);

  return (
    <>
      {appReady && authenticated && (
        <Router history={history}>
          <AuthLayout>
            <Switch>
              <Route exact={true} path={Routes.HOME} component={HomePage} />
              <Route
                path={Routes.INVENTORY_LIST}
                component={InventoryItemsPage}
              />
              <Route path={Routes.LOT_HISTORY} component={LotHistoryPage} />
              <Route path={Routes.NEW_LOT} component={NewLotPage} />
              <Route path={Routes.PRINT_LOT} component={PrintLotPage} />
              <Redirect to={Routes.INVENTORY_LIST} />
            </Switch>
          </AuthLayout>
        </Router>
      )}

      {appReady && !authenticated && (
        <BasicLayout>
          <Router history={history}>
            <Switch>
              <Route exact={true} path={Routes.LOGIN} component={LoginPage} />
              <Redirect to={Routes.LOGIN} />
            </Switch>
          </Router>
        </BasicLayout>
      )}
    </>
  );
}

export default App;
