import { action } from 'typesafe-actions';
import {
  LotActionTypes,
  LoadNewLotNumberAction,
  StoreNewLotNumberAction,
  GenerateLabelsAction,
  StoreCurrentLotAction,
  LoadLotHistoryAction,
  StoreLotHistoryListAction,
  ResetLotAction,
} from '../../types/lotActions';
import { INewLotFormFields } from '../../types';
import { LotNoDocEntity } from '../../types/inventoryItemActions';

export const loadNewLotNumberAction = (): LoadNewLotNumberAction => action(LotActionTypes.LOAD_NEW_LOT_NUMBER);
export const storeNewLotNumberAction = (payload: string): StoreNewLotNumberAction => action(LotActionTypes.STORE_NEW_LOT_NUMBER, payload);
export const storeCurrentLotAction = (payload?: LotNoDocEntity): StoreCurrentLotAction => action(LotActionTypes.STORE_CURRENT_LOT, payload);
export const generateLabelsAction = (payload: INewLotFormFields): GenerateLabelsAction => action(LotActionTypes.GENERATE_LABELS, payload);
export const loadLotHistoryAction = (): LoadLotHistoryAction => action(LotActionTypes.LOAD_LOT_HISTORY);
export const resetLotAction = (): ResetLotAction => action(LotActionTypes.RESET_LOT_REDUCER);
export const storeLotHistoryListAction = (payload: LotNoDocEntity[]): StoreLotHistoryListAction => action(LotActionTypes.STORE_LOT_HISTORY_LIST, payload);
