import { TFunction } from 'i18next';
import { INewLotFormFields } from '../types';

export const validateNewLotForm = (values: INewLotFormFields, t: TFunction) => {
  const errors: any = {};
  if (!values.quantity){
    errors.quantity = t('validationMessages.required');
  }
  return errors;
};
