import React, { ReactElement } from "react";
import { InfoModal } from "../../components/InfoModal";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { ReactComponent as CsLogo } from "../../images/cz.svg";
// @ts-ignore
import { ReactComponent as GbLogo } from "../../images/gb.svg";

// @ts-ignore
import Logo from "../../Logo.gif";
import {
  AppBar,
  Container,
  CssBaseline,
  IconButton,
  Toolbar,
} from "@mui/material";
import Routes from "../../utils/Routes";
import { NavLink } from "react-router-dom";

interface LocalProps {
  children: ReactElement;
}

const sxStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: 80,
  },
  logoImage: {
    marginTop: 0,
  },
  content: {
    paddingTop: 40,
  },
};

export const BasicLayout = (props: LocalProps) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={sxStyles.toolbar}>
            <img style={sxStyles.logoImage} src={Logo} />
          <div>
            <h1>Orto Stickers</h1>
          </div>
          <div>
            <IconButton onClick={() => changeLanguage("en")}>
              <GbLogo />
            </IconButton>
            <IconButton onClick={() => changeLanguage("cs")}>
              <CsLogo />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Container maxWidth="sm">
        <CssBaseline />
        <div style={sxStyles.content}>{props.children}</div>
        <InfoModal />
      </Container>
    </>
  );
};
