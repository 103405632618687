const setItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const getItem = (key: string): any => {
    const item = localStorage.getItem(key);
    if (item && item !== 'undefined') {
        return JSON.parse(item);
    }
    return undefined;
};

const removeItem = (key: string) => {
    localStorage.removeItem(key);
};

const clear = () => {
    localStorage.clear();
};

export default {
    setItem,
    getItem,
    removeItem,
    clear
};
