import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../redux/selectors/appSelectors";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";

interface UserProfileDialogProps {
  onClose: () => void;
  open: boolean;
}

export const UserProfileDialog = (props: UserProfileDialogProps) => {
  const { t } = useTranslation();
  const userDetails = useSelector(selectUserDetails);
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("UserProfileDialog.title")}</DialogTitle>
      <DialogContent dividers>{t("UserProfileDialog.text")}</DialogContent>

      <DialogContent dividers>
        {userDetails?.roles.filter((item)=>{return item.authority.startsWith('ROLE_PRODUCER')}).map((item)=>{return item.authority.substr(item.authority.length -1,item.authority.length)})}
      </DialogContent>
    </Dialog>
  );
};
