import { call, put, takeEvery } from 'redux-saga/effects';
import {AppActionTypes, AppAuthenticateAction, AppConfig, AuthResponseObject, HelloResponseObject, OResponse, UserDetail} from '../../types';
import { appReadyAction, appStoreAuthenticateAction, appStoreConfigAction } from '../actions/appActions';
import api, { axiosInstance, processResponseStatus } from '../../api/api';
import storage from '../../utils/localStorage';
import { ILoginFormFields } from '../../types';
import { push } from 'react-router-redux';
import Routes from '../../utils/Routes';
import { storeFormAction } from '../actions/formActions';
import jwt_decode from "jwt-decode";

export function* loadConfigSaga(): Generator {
    // @ts-ignore
    const config: AppConfig = { apiUrl: window.API_URL, appMode: window.APP_MODE, appName: window.APP_NAME, debugMode: window.APP_MODE === 'development' };
    api.init(config);

    yield put(appStoreConfigAction(config));
    let response: OResponse<HelloResponseObject> = (yield call(axiosInstance.hello)) as OResponse<HelloResponseObject>;
    response = processResponseStatus(response);
    if (response.isHttpOk) {
        if (response.data && response.data.status === 'OK') {
            const token = storage.getItem('token');
            let expired = true;
            try {
                expired = isTokenExpired(token);
            } catch (e){
                console.log('Token Expired?', e);
            }

            if (expired) {
                storage.clear();
            }
            if (!expired) {
                const response: OResponse<any> = (yield call(axiosInstance.jwtCheck, { token })) as OResponse<any>;
                if (response.data && response.data.status === 'OK') {
                    const userDetail:UserDetail = jwt_decode(token);
                    api.init(config);
                    yield put(appStoreAuthenticateAction(userDetail));
                    yield put(push(Routes.HOME));
                }
            }
            yield put(appReadyAction());
        }
    }
}

const isTokenExpired=(token:string) =>{
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
}

export function* authenticateSaga( action: AppAuthenticateAction): Generator {
    let response: OResponse<AuthResponseObject> = (yield call(axiosInstance.authenticate, action.payload)) as OResponse<AuthResponseObject>;
    response = processResponseStatus(response);
    if (response.isHttpOk) {
        storage.setItem('token', response.data.result.jwt);
        const userDetail:UserDetail = jwt_decode(response.data.result.jwt);
        yield put(appStoreAuthenticateAction(userDetail));
        const fromData:ILoginFormFields = { ...action.payload, password: '' };
        yield put(storeFormAction(fromData));
        yield put(push(Routes.HOME));
    }
}

export function* appSagas(): Generator {
    yield takeEvery(AppActionTypes.LOAD_CONFIG, loadConfigSaga);
    yield takeEvery(AppActionTypes.APP_AUTHENTICATE, authenticateSaga);
}
