import {TFunction} from "i18next";
import {ILoginFormFields} from "../types";

export const validateLoginForm=(values:ILoginFormFields,t: TFunction)=>{
    const errors: any = {};
    if (!values.username) {
        errors.username = t('validationMessages.required');
    }
    if (!values.password) {
        errors.password = t('validationMessages.required');
    }
    return errors;
}
