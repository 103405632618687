import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from '../../utils/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { InfoModal } from '../../components/InfoModal';
// @ts-ignore
import Logo from '../../Logo.gif';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { ReactComponent as GbLogo } from '../../images/gb.svg';
// @ts-ignore
import { ReactComponent as CsLogo } from '../../images/cz.svg';
import { selectMenuKey, selectUserDetails } from '../../redux/selectors/appSelectors';
import { UserProfileDialog } from '../profile/UserProfileDialog';
import { storeMenuAction } from '../../redux/actions/appActions';
import { MenuKeys } from '../../utils/constants';
import { AppBar, CssBaseline, Drawer, IconButton, MenuItem, Toolbar } from '@mui/material';

const sxStyles = {
  root: {
    display: 'flex',
    width: '100%',
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  hide: {
    display: 'none',
  },
  content: {
    marginTop: 55,
    display: 'flex',
    width: '100%',
  },
  logoImage: {
    marginTop: 0,
    cursor: 'pointer',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 80,
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfo: {
    marginRight: 20,
  },
  userName: {
    cursor: 'pointer',
  },
  drawer: {
    '& .MuiDrawer-paper': {
      top: 80,
      width: 150,
      boxShadow: '0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },
  main: {
    paddingLeft: 24,
    paddingTop: 24,
    width: '100%',
  },
};

interface LocalProps {
  children: ReactElement;
}

export const AuthLayout = (props: LocalProps) => {
  const { t, i18n } = useTranslation();
  const userDetails = useSelector(selectUserDetails);
  const menuKey = useSelector(selectMenuKey);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeMenu = (key: MenuKeys) => {
    dispatch(storeMenuAction(key));
    switch (key) {
      case MenuKeys.INVENTORY_LIST:
        history.push(Routes.INVENTORY_LIST);
        break;
      default: {
        history.push(Routes.INVENTORY_LIST);
        break;
      }
    }
  };

  return (
    <div style={sxStyles.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={sxStyles.toolbar}>
          <img
            onClick={() => {
              changeMenu(MenuKeys.INVENTORY_LIST);
            }}
            style={sxStyles.logoImage}
            src={Logo}
          />
          <div>
            <h1>Orto Stickers</h1>
          </div>
          <div style={sxStyles.rightItems}>
            {userDetails && (
              <div style={sxStyles.userInfo}>
                <div>{t('toolbar.loggedUser')}</div>
                <div style={sxStyles.userName} onClick={handleClickOpen}>
                  {userDetails.sub}
                </div>
                <UserProfileDialog open={open} onClose={handleClose} />
              </div>
            )}
            <div>
              <IconButton onClick={() => changeLanguage('en')}>
                <GbLogo />
              </IconButton>
              <IconButton onClick={() => changeLanguage('cs')}>
                <CsLogo />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div style={sxStyles.content}>
        <div style={sxStyles.main}>{props.children}</div>
        <InfoModal />
      </div>
    </div>
  );
};
