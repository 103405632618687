import { ILoginFormFields, INewLotFormFields, OrderAddPhotoFields } from '../types';
import { JwtCheckRequest } from './api';

export interface Endpoint {
  method: string;
  url: string;
  secured: boolean;
}

export interface ApiEndpoints {
  [key: string]: Endpoint;
}

export interface NewLotNoRequest {
  producerCode: string;
}

export interface LotHistoryRequest {
  producerCode: string;
  ortoId: string;
  variant: string;
}

export interface ApiInterface {
  hello: () => Promise<any>;
  authenticate: (param: ILoginFormFields) => Promise<any>;
  jwtCheck: (param: JwtCheckRequest) => Promise<any>;
  getOrderDetail: (param: OrderAddPhotoFields) => Promise<any>;
  inventoryItems: () => Promise<any>;
  newLotNo: (params: NewLotNoRequest) => Promise<string>;
  getLotHistory: (params: LotHistoryRequest) => Promise<any>;
  generateNewLot: (params: INewLotFormFields) => Promise<string>;
}

export const apiEndpoints: ApiEndpoints = {
  hello: {
    method: 'GET',
    url: '/hello',
    secured: false,
  },
  authenticate: {
    method: 'POST',
    url: '/authenticateStickerApp',
    secured: false,
  },
  jwtCheck: {
    method: 'POST',
    url: '/jwtCheck',
    secured: false,
  },
  inventoryItems: {
    method: 'POST',
    url: '/stickers/inventoryItems',
    secured: true,
  },
  newLotNo: {
    method: 'POST',
    url: '/stickers/newLotNo',
    secured: true,
  },
  generateNewLot: {
    method: 'POST',
    url: '/stickers/generateNewLot',
    secured: true,
  },
  getLotHistory: {
    method: 'POST',
    url: '/stickers/getLotHistory',
    secured: true,
  },
};
