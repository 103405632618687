import React, {CSSProperties} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfo } from '../redux/selectors/infoSelectors';
import {InfoMessage, MyStyled} from '../types';
import { deleteInfoAction } from '../redux/actions/infoActions';
import { useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { Button, LinearProgress, Modal, Paper} from "@mui/material";

const sxStyles: MyStyled = {
  paperINFO: {
    border: "1px solid #3f51b5",
  },
  paperWARNING: {
    border: "1px solid #edb17d",
  },
  paperERROR: {
    border: "1px solid #ff0000",
  },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const InfoModal = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const infoMessage:InfoMessage|undefined = useSelector(selectInfo);
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(deleteInfoAction());
        if (infoMessage?.routeToGo) {
            history.push(infoMessage?.routeToGo);
        }
    };
    let paperClass:CSSProperties = sxStyles.paperINFO;

    if (infoMessage?.severity === 'ERROR') {
        paperClass = sxStyles.paperERROR;
    } else if (infoMessage?.severity === 'WARNING') {
        paperClass = sxStyles.paperWARNING;
    }

    return (
        <>
            {infoMessage &&
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
            >
                <Paper sx={style} style={paperClass}>
                        <h2 id="transition-modal-title">{t(infoMessage.title)}</h2>
                        {infoMessage.type === 'progress' && <LinearProgress variant="determinate" value={infoMessage?.progress}/>}
                        {infoMessage.type === 'info' &&
                            <Button color="primary" onClick={handleCloseModal} variant="contained" style={{float:'right'}}>
                                OK
                            </Button>
                        }
                </Paper>
            </Modal>
            }
        </>
    );
};
