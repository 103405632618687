import { call, put, takeEvery } from 'redux-saga/effects';
import {DataResponse} from '../../types';
import { axiosInstance } from '../../api/api';

import {IInventoryItem, InventoryItemsActionsTypes} from "../../types/inventoryItemActions";
import { storeAllInventoryItemsAction, storeCurrentInventoryItemAction } from '../actions/inventoryItemActions';
import { resetLotAction } from '../actions/lotActions';

export function* loadInventoryItemsSaga( ): Generator {
    let response: DataResponse<IInventoryItem[]> = (yield call(axiosInstance.inventoryItems)) as DataResponse<IInventoryItem[]>;
    yield put(storeAllInventoryItemsAction(response.data));
    yield put(resetLotAction());
    yield put(storeCurrentInventoryItemAction());
}

export function* inventoryItemsSagas(): Generator {
    yield takeEvery(InventoryItemsActionsTypes.LOAD_All_ITEMS, loadInventoryItemsSaga);
}
