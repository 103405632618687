import { IForm } from './forms';
import { AnyAction } from 'redux';

export enum FormActionTypes {
    STORE_FORM = 'form/STORE_FORM',
}

export interface StoreFormAction {
    type: FormActionTypes.STORE_FORM
    payload: IForm
}

export type FormAction =
    StoreFormAction | AnyAction

