import { call, put, takeEvery, select } from 'redux-saga/effects';
import { DataResponse, INewLotFormFields } from '../../types';
import { axiosInstance } from '../../api/api';
import { IInventoryItem, LotNoDocEntity } from '../../types/inventoryItemActions';
import { GenerateLabelsAction, LotActionTypes } from '../../types/lotActions';
import { selectCurrentInventoryItem } from '../selectors/inventoryItemSelectors';
import { LotHistoryRequest, NewLotNoRequest } from '../../api/endpoints';
import { storeLotHistoryListAction, storeCurrentLotAction, storeNewLotNumberAction } from '../actions/lotActions';
import { storeFormAction } from '../actions/formActions';

export function* loadLotHistorySaga(): Generator {
  const selectedItem: IInventoryItem | undefined = (yield select(selectCurrentInventoryItem)) as IInventoryItem;
  if (selectedItem) {
    const lotHistoryRequest: LotHistoryRequest = {
      ortoId: `${selectedItem.ortoId}`,
      producerCode: selectedItem.productionLocation.code,
      variant: selectedItem.variant,
    };
    let response: DataResponse<LotNoDocEntity[]> = (yield call(axiosInstance.getLotHistory, lotHistoryRequest)) as DataResponse<LotNoDocEntity[]>;
    yield put(storeLotHistoryListAction(response.data));
  }
}

export function* loadNewLotNumberSaga(): Generator {
  const selectedItem: IInventoryItem | undefined = (yield select(selectCurrentInventoryItem)) as IInventoryItem;
  if (selectedItem) {
    const newLotNoRequest: NewLotNoRequest = {
      producerCode: selectedItem?.productionLocation.code,
    };
    let response: DataResponse<string> = (yield call(axiosInstance.newLotNo, newLotNoRequest)) as DataResponse<string>;
    yield put(storeNewLotNumberAction(response.data));

    const formValues: INewLotFormFields = {
      currentForm: 'newLot',
      name: selectedItem.name,
      ortoId_variant: `${selectedItem.ortoId}_${selectedItem.variant}`,
      productionDate: new Date(),
      lotCode: response.data,
      itemId: selectedItem.id,
      mainImageId: selectedItem.mainImageId,
    };
    yield put(storeFormAction(formValues));
  }
}

export function* generateLabelsSaga(action: GenerateLabelsAction): Generator {
  yield put(storeFormAction(action.payload));
  let response: DataResponse<LotNoDocEntity> = (yield call(axiosInstance.generateNewLot, action.payload)) as DataResponse<LotNoDocEntity>;
  yield put(storeCurrentLotAction(response.data));

  const formValues: INewLotFormFields = {
    currentForm: 'newLot',
    name: response.data.name,
    ortoId_variant: `${response.data.ortoId}_${response.data.variant}`,
    productionDate: response.data.productionDate,
    lotCode: response.data.lotCode,
    itemId: response.data.itemId,
    mainImageId: response.data.mainImageId,
    quantity: response.data.quantity,
    packageDocumentId: response.data.packageDocumentId,
    productDocumentId: response.data.packageDocumentId,
  };
  yield put(storeFormAction(formValues));
}

export function* lotSagas(): Generator {
  yield takeEvery(LotActionTypes.LOAD_NEW_LOT_NUMBER, loadNewLotNumberSaga);
  yield takeEvery(LotActionTypes.LOAD_LOT_HISTORY, loadLotHistorySaga);
  yield takeEvery(LotActionTypes.GENERATE_LABELS, generateLabelsSaga);
}
