import { AnyAction } from 'redux';
import { INewLotFormFields } from './forms';
import { LotNoDocEntity } from './inventoryItemActions';

export enum LotActionTypes {
  LOAD_NEW_LOT_NUMBER = 'lot/GET_NEW_LOT_NUMBER',
  LOAD_LOT_HISTORY = 'lot/LOAD_LOT_HISTORY',
  STORE_NEW_LOT_NUMBER = 'lot/STORE_NEW_LOT_NUMBER',
  GENERATE_LABELS = 'lot/GENERATE_LABELS',
  STORE_CURRENT_LOT = 'lot/STORE_CURRENT_LOT',
  STORE_LOT_HISTORY_LIST = 'lot/STORE_LOT_HISTORY',
  RESET_LOT_REDUCER = 'lot/RESET_LOT_REDUCER',
}

export interface LoadNewLotNumberAction {
  type: LotActionTypes.LOAD_NEW_LOT_NUMBER;
}
export interface LoadLotHistoryAction {
  type: LotActionTypes.LOAD_LOT_HISTORY;
}

export interface GenerateLabelsAction {
  type: LotActionTypes.GENERATE_LABELS;
  payload:INewLotFormFields
}

export interface StoreNewLotNumberAction {
  type: LotActionTypes.STORE_NEW_LOT_NUMBER;
  payload: string;
}
export interface StoreCurrentLotAction {
  type: LotActionTypes.STORE_CURRENT_LOT;
  payload?: LotNoDocEntity;
}
export interface StoreLotHistoryListAction {
  type: LotActionTypes.STORE_LOT_HISTORY_LIST;
  payload: LotNoDocEntity[];
}
export interface ResetLotAction {
  type: LotActionTypes.RESET_LOT_REDUCER;
  payload?: undefined;
}

export type LotAction = StoreLotHistoryListAction | StoreCurrentLotAction | StoreNewLotNumberAction | AnyAction;
