import { Reducer } from 'redux';
import { ILotState } from '../../types';
import { LotAction, LotActionTypes } from '../../types/lotActions';

const initialState: ILotState = {
  lotHistory: [],
};

export const lotReducer: Reducer<ILotState> = (state = { ...initialState, lotHistory: [] }, action: LotAction) => {
  switch (action.type) {
    case LotActionTypes.STORE_NEW_LOT_NUMBER: {
      return { ...state, newLotNumber: action.payload };
    }
    case LotActionTypes.STORE_CURRENT_LOT: {
      return { ...state, currentLot: action.payload };
    }
    case LotActionTypes.STORE_LOT_HISTORY_LIST: {
      return { ...state, lotHistory: action.payload };
    }
    case LotActionTypes.RESET_LOT_REDUCER: {
      return { lotHistory: [] };
    }
    default:
      return state;
  }
};
