import React from 'react';
import {Container, Grid} from "@mui/material";

export const HomePage = () => {
    return (
        <Container  maxWidth="xs">
            <Grid container={true} spacing={3}>
                homePage
            </Grid>
        </Container>
    );
};
