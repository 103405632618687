import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { appReducer } from './reducers/appReducer';
import { infoReducer } from './reducers/infoReducer';
import { formReducer } from './reducers/formReducer';
import { inventoryItemsReducer } from './reducers/inventoryItemsReducer';
import { lotReducer } from './reducers/lotReducer';

export const reducer = combineReducers<any>({
  form: formReducer,
  app: appReducer,
  info: infoReducer,
  inventory: inventoryItemsReducer,
  lot: lotReducer,
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let dispatch: any;

export default () => {
  const middleware = [];
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  const store: any = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
  store.sagaMiddleware = sagaMiddleware;
  dispatch = store.dispatch;
  return store;
};

export { dispatch };
