import {AppConfig, ReduxState, UserDetail} from '../../types';

export const selectAppReady = (state: ReduxState): boolean => {
    return state.app.ready;
};
export const selectAppAuthenticated = (state: ReduxState): boolean => {
    return state.app.authenticated;
};

export const selectUserDetails = (state: ReduxState): UserDetail | undefined => {
    return state.app.userDetail;
};
export const selectMenuKey = (state: ReduxState): string => {
    return state.app.menuKey;
};
export const selectAppConfig = (state: ReduxState): AppConfig | undefined => {
    return state.app.config;
};

