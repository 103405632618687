import { action } from 'typesafe-actions';
import {
    AppActionTypes,
    AppAuthenticateAction,
    AppConfig,
    AppReadyAction,
    AppStoreAuthenticateAction,
    ILoginFormFields,
    LoadConfigAction,
    StoreConfigAction,
    StoreMenuActionAction,
    UserDetail
} from '../../types';

export const appStoreConfigAction = (payload: AppConfig): StoreConfigAction =>
    action(AppActionTypes.STORE_CONFIG, payload);

export const appLoadConfigAction = (): LoadConfigAction =>
    action(AppActionTypes.LOAD_CONFIG);

export const appReadyAction = (): AppReadyAction =>
    action(AppActionTypes.APP_READY);

export const appAuthenticateAction = (values:ILoginFormFields): AppAuthenticateAction =>
    action(AppActionTypes.APP_AUTHENTICATE, values);

export const appStoreAuthenticateAction = (payload:UserDetail): AppStoreAuthenticateAction =>
    action(AppActionTypes.STORE_AUTHENTICATE,payload);

export const storeMenuAction = (payload:string): StoreMenuActionAction =>
    action(AppActionTypes.STORE_MENU,payload);
