import { IForm, ILoginFormFields, INewLotFormFields } from './forms';
import { LotNoDocEntity } from './inventoryItemActions';

export interface InfoMessage {
  progress?: number;
  title: string;
  type: 'progress' | 'info';
  routeToGo?: string;
  severity?: 'ERROR' | 'WARNING' | 'INFO';
}

export interface IInfoState {
  message?: InfoMessage;
}

export interface ILotState {
  newLotNumber?: string;
  currentLot?: LotNoDocEntity;
  lotHistory: LotNoDocEntity[];
}

export interface IFormState {
  login: ILoginFormFields;
  newLot: INewLotFormFields;
}

export enum InfoActionTypes {
  ADD_INFO = 'info/ADD_INFO',
  DELETE_INFO = 'info/DELETE_INFO',
}

export interface AddInfoAction {
  type: InfoActionTypes.ADD_INFO;
  payload: InfoMessage;
}

export interface DeleteInfoAction {
  type: InfoActionTypes.DELETE_INFO;
  payload?: any;
}

export type InfoAction = AddInfoAction | DeleteInfoAction;
