import { ReduxState } from '../../types';
import { IInventoryItem, LotNoDocEntity } from '../../types/inventoryItemActions';

export const selectInventoryItems = (state: ReduxState): IInventoryItem[] => {
    return state.inventory.inventoryItems;
};
export const selectHistoryLots = (state: ReduxState): LotNoDocEntity[] => {
    return state.lot.lotHistory;
};

export const selectCurrentInventoryItem = (state: ReduxState): IInventoryItem | undefined => {
    return state.inventory.currentInventoryItem;
};
