import { all, fork, take, cancel } from 'redux-saga/effects';
import { Store } from 'redux';
import { appSagas } from './sagas/appSagas';
import {inventoryItemsSagas} from "./sagas/inventoryItemsSagas";
import {lotSagas} from "./sagas/lotSagas";

export const CANCEL_ROOT_SAGA = 'CANCEL_ROOT_SAGA';

export function* rootSaga(): Generator {
    yield all([fork(appSagas)]);
    yield all([fork(inventoryItemsSagas)]);
    yield all([fork(lotSagas)]);
}

export function* cancellableSaga(): Generator {
    // start the root saga
    const task = yield fork(rootSaga);

    // cancelling mechanism
    yield take(CANCEL_ROOT_SAGA);
    // @ts-ignore
    yield cancel(task);
}

export default function createSagaManager(store: Store) {
    return {
        cancel: () => store.dispatch({ type: CANCEL_ROOT_SAGA }),
        start: () => (store as any).sagaMiddleware.run(cancellableSaga)
    };
}
