import React, { useEffect } from 'react';
import { Breadcrumbs, CircularProgress, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Routes from '../utils/Routes';
import { NavLink, useHistory } from 'react-router-dom';
import { generateLabelsAction, loadNewLotNumberAction, storeCurrentLotAction } from '../redux/actions/lotActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentInventoryItem } from '../redux/selectors/inventoryItemSelectors';
import { Form, FormRenderProps } from 'react-final-form';
import { INewLotFormFields } from '../types';
import { validateNewLotForm } from './NewLotPage.validate';
import { selectAppConfig } from '../redux/selectors/appSelectors';
import { DatePicker, TextField } from 'mui-rff';
import { selectNewLotFields } from '../redux/selectors/formSelectors';
import csLocale from 'date-fns/locale/cs';
import enLocale from 'date-fns/locale/en-US';
import Button from '@mui/material/Button';

export const NewLotPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const inventoryItem = useSelector(selectCurrentInventoryItem);
  const initialValues = useSelector(selectNewLotFields);
  const appConfig = useSelector(selectAppConfig);
  const history = useHistory();
  const lagSelected: string = i18n.language;
  const locale: any = lagSelected === 'cs' ? csLocale : enLocale;
  useEffect(() => {
    dispatch(loadNewLotNumberAction());
  }, []);

  const handleSubmit = (props: INewLotFormFields) => {
    storeCurrentLotAction(undefined);
    dispatch(generateLabelsAction(props));
    history.push(Routes.PRINT_LOT);
  };

  return (
    <Container style={{ width: '100%', maxWidth: '100%', paddingTop: 10 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to={Routes.INVENTORY_LIST}>{t('InventoryItemsPage.title')}</NavLink>
        <NavLink to={Routes.NEW_LOT}>{t('NewLotPage.title')}</NavLink>
      </Breadcrumbs>
      <h1>{t('NewLotPage.title')}</h1>
      {inventoryItem ? <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={(values) => validateNewLotForm(values, t)}
        subscription={{ values: true, submitting: true }}
        render={(props: FormRenderProps<INewLotFormFields>) => {
          const { handleSubmit, values } = props;
          return (
            <form onSubmit={handleSubmit} noValidate={true}>
              <Grid component={'div'} container spacing={2}>
                <Grid component={'div'} item xs={3}>
                  <img width={200} src={`${appConfig?.apiUrl}/listItemImages/${inventoryItem?.mainImageId}`} />
                </Grid>
                <Grid component={'div'} item xs={5}>
                  <TextField label={t('NewLotPage.ortoId')} name="ortoId_variant" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <TextField label={t('NewLotPage.name')} name="name" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <TextField label={t('NewLotPage.lotCode')} name="lotCode" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <DatePicker minDate={new Date()} label={t('NewLotPage.productionDate')} name="productionDate" locale={locale} />
                  <br /> <br />
                  <TextField
                    type={'number'}
                    label={t('NewLotPage.quantity')}
                    name="quantity"
                    required={true}
                    inputProps={{ type: 'number', pattern: '[0-9]+' }}
                  />
                  <br /> <br />
                  <Button color={'primary'} variant="contained" type={'submit'}>
                    {t('buttons.generateLabels')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />:<CircularProgress />}
    </Container>
  );
};
