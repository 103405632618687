import { Reducer } from 'redux';
import { IFormState } from '../../types';
import { FormAction, FormActionTypes } from '../../types/formActions';

const initialState: IFormState = {
    login: { currentForm: 'login' },
    newLot: { currentForm: 'newLot' }
};

export const formReducer: Reducer<IFormState> = (state = initialState, action: FormAction) => {
    switch (action.type) {
    case FormActionTypes.STORE_FORM: {
        return { ...state, [action.payload.currentForm]: action.payload };
    }
    default:
        return state;
    }
};
