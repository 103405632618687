import React, { useEffect } from 'react';
import { Breadcrumbs, CircularProgress, Container, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Routes from '../utils/Routes';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { csCZ, DataGrid, enUS, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { selectHistoryLots } from '../redux/selectors/inventoryItemSelectors';
import { LotNoDocEntity } from '../types/inventoryItemActions';
import { selectAppConfig } from '../redux/selectors/appSelectors';
import moment from 'moment';
import Button from '@mui/material/Button';
import { storeCurrentLotAction } from '../redux/actions/lotActions';

export const LotHistoryPage = () => {
  const { t, i18n } = useTranslation();
  const appConfig = useSelector(selectAppConfig);
  const lagSelected: string = i18n.language;
  const dataGridLanguage: any = lagSelected === 'cs' ? csCZ : enUS;
  const dispatch = useDispatch();
  const historyLots: LotNoDocEntity[] = useSelector(selectHistoryLots);
  const history = useHistory();
  useEffect(() => {}, []);

  const showHistoryLabelPrint = (params: GridRenderCellParams) => {
    dispatch(storeCurrentLotAction(params.row));
    history.push(Routes.PRINT_LOT);
  };

  const columns: GridColDef[] = [
    {
      field: 'mainImageId',
      headerName: t('LotHistoryPage.picture'),
      width: 90,
      renderCell: (params) => <img height={90} src={`${appConfig?.apiUrl}/listItemImages/${params.value}`} />,
    },
    {
      field: 'ortoId',
      headerName: t('LotHistoryPage.ortoId_variant'),
      width: 110,
      renderCell: (params) => `${params.row.ortoId}_${params.row.variant}`,
    },
    { field: 'name', headerName: t('LotHistoryPage.item'), width: 350 },
    { field: 'lotCode', headerName: t('LotHistoryPage.lot'), width: 100 },
    {
      field: 'productionDate',
      headerName: t('LotHistoryPage.productionDate'),
      width: 150,
      renderCell: (params) => moment(params.value).format('YYYY-MM-DD'),
    },
    { field: 'quantity', headerName: t('LotHistoryPage.quantity'), width: 100 },
    {
      field: '',
      headerName: '',
      width: 350,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <Button
            color={'primary'}
            variant="contained"
            onClick={() => {
              showHistoryLabelPrint(params);
            }}
          >
            {t('buttons.historyLotPrint')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container style={{ width: '100%', maxWidth: '100%', paddingTop: 10 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to={Routes.INVENTORY_LIST}>{t('InventoryItemsPage.title')}</NavLink>
        <NavLink to={Routes.LOT_HISTORY}>{t('LotHistoryPage.title')}</NavLink>
      </Breadcrumbs>
      <h1>{t('LotHistoryPage.title')}</h1>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
            rows={historyLots}
            columns={columns}
            autoHeight={true}
            rowHeight={100}
          />
        </div>
    </Container>
  );
};
