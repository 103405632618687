import React, { useEffect } from 'react';
import { Breadcrumbs, CircularProgress, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Routes from '../utils/Routes';
import { NavLink } from 'react-router-dom';
import { loadNewLotNumberAction } from '../redux/actions/lotActions';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormRenderProps } from 'react-final-form';
import { INewLotFormFields } from '../types';
import { selectAppConfig } from '../redux/selectors/appSelectors';
import { DatePicker, TextField } from 'mui-rff';
import csLocale from 'date-fns/locale/cs';
import enLocale from 'date-fns/locale/en-US';
import Button from '@mui/material/Button';
import { selectCurrentLot } from '../redux/selectors/lotSelectors';
import { LotNoDocEntity } from '../types/inventoryItemActions';

export const PrintLotPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLot: LotNoDocEntity | undefined = useSelector(selectCurrentLot);

  const initialValues: INewLotFormFields = {
    currentForm: 'printForm',
    itemId:currentLot?.itemId,
    lotCode: currentLot?.lotCode,
    mainImageId: currentLot?.mainImageId,
    name: currentLot?.name,
    ortoId_variant: `${currentLot?.ortoId}_${currentLot?.variant}`,
    packageDocumentId: currentLot?.packageDocumentId,
    productDocumentId: currentLot?.productDocumentId,
    productionDate: currentLot?.productionDate,
    quantity: currentLot?.quantity,
  };
  const appConfig = useSelector(selectAppConfig);
  const lagSelected: string = i18n.language;
  const locale: any = lagSelected === 'cs' ? csLocale : enLocale;
  useEffect(() => {
    dispatch(loadNewLotNumberAction());
  }, []);

  const handleSubmit = (props: INewLotFormFields) => {};

  const onDownload = (pdfId?:number) => {
    if(pdfId) {
      const link = document.createElement("a");
      link.download = `download.pdf`;
      link.href = `${appConfig?.apiUrl}/getStickerPdf/${pdfId}`;
      link.click();
    }
  };
  return (
    <Container style={{ width: '100%', maxWidth: '100%', paddingTop: 10 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink to={Routes.INVENTORY_LIST}>{t('InventoryItemsPage.title')}</NavLink>
        <NavLink to={Routes.PRINT_LOT}>{t('PrintLotPage.title')}</NavLink>
      </Breadcrumbs>
      <h1>{t('PrintLotPage.title')}</h1>
      { currentLot ? <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        subscription={{ values: true, submitting: true }}
        render={(props: FormRenderProps<INewLotFormFields>) => {
          const { handleSubmit, values } = props;
          return (
            <form onSubmit={handleSubmit} noValidate={true}>
              <Grid component={'div'} container spacing={2}>
                <Grid component={'div'} item xs={3}>
                  {values?.mainImageId && <img width={200} src={`${appConfig?.apiUrl}/listItemImages/${values?.mainImageId}`} /> }
                </Grid>
                <Grid component={'div'} item xs={5}>
                  <TextField label={t('PrintLotPage.ortoId')} name="ortoId_variant" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <TextField label={t('PrintLotPage.name')} name="name" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <TextField label={t('PrintLotPage.lotCode')} name="lotCode" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <DatePicker label={t('PrintLotPage.productionDate')} name="productionDate" locale={locale} readOnly={true} disableMaskedInput={true} disableOpenPicker={true} />
                  <br /> <br />
                  <TextField label={t('PrintLotPage.quantity')} name="quantity" aria-readonly={true} inputProps={{ readOnly: true }} />
                  <br /> <br />
                  <Grid component={'div'} container spacing={2}>
                    <Grid component={'div'} item xs={12}>
                      {initialValues.packageDocumentId && <Button
                        onClick={() => onDownload(initialValues.packageDocumentId)}
                        variant="contained"
                        color={'primary'}
                        size="large"
                        fullWidth={true}
                        startIcon={<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
                          <g>
                            <rect fill="none" height="24" width="24" />
                          </g>
                          <g>
                            <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
                          </g>
                        </svg>}
                      >
                        {t('PrintLotPage.packagePdf')}
                      </Button>
                      }
                    </Grid>
                    <Grid component={'div'} item xs={12}>
                      {initialValues.productDocumentId && <Button
                        onClick={()=>onDownload(initialValues.productDocumentId)}
                        variant="contained"
                        color={'primary'}
                        size="large"
                        fullWidth={true}
                        startIcon={<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/></g></svg>}
                      >
                        {t('PrintLotPage.productPdf')}
                      </Button> }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />:<CircularProgress />}
    </Container>
  );
};
