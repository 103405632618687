
export default {
    LOGIN: '/login',
    HOME: '/',
    INVENTORY_LIST: '/inventoryList',
    LOT_HISTORY: '/lotHistory',
    NEW_LOT: '/newLot',
    PRINT_LOT: '/printLot',
    SEARCH_EVIDENCE_NUMBER: '/searchByEvidenceNumber',
    ADD_PHOTO: '/addPhoto',
    VIEW_TAKEN_PHOTOS: '/viewTakenPhotos',
    ORDER_ADD_PHOTO_PAGE: '/orderAddPhotoPage'
};
