import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import createSagaManager from './redux/configureSagas';
import './i18n';

export const store = configureStore();
createSagaManager(store).start();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
